<template>
  <div class="w-100 d-flex align-items-center justify-content-center">
    <a-card class="new-password-card">
      <h2 class="text-center mb-5">Ubah Password</h2>
      <a-form :model="passwordData" @submit.prevent="handleChangePassword" class="mt-2">
        <!-- Password Saat Ini -->

        <div class="row form-item">
          <div class="col-12 col-sm-3 label-col"><label>Password Saat Ini:</label></div>
          <div class="col-12 col-sm-9 d-flex align-items-center justify-content-center">
            <a-input-password v-model:value="passwordData.currentPassword" />
          </div>
        </div>

        <!-- Password Baru -->
        <div class="row form-item">
          <div class="col-12 col-sm-3 label-col"><label>Password Baru:</label></div>
          <div class="col-12 col-sm-9 d-flex align-items-center justify-content-center">
            <a-input-password v-model:value="passwordData.newPassword" />
          </div>
        </div>

        <!-- Konfirmasi Password -->
        <div class="row form-item">
          <div class="col-12 col-sm-3 label-col"><label>Konfirmasi Password:</label></div>
          <div class="col-12 col-sm-9 d-flex align-items-center justify-content-center">
            <a-input-password v-model:value="passwordData.confirmPassword" />
          </div>
        </div>

        <div class="button-container text-right mt-4">
          <a-button type="primary" html-type="submit" class="custom-button-yes" :loading="loading">
            <strong>Ubah Password</strong>
          </a-button>
        </div>
      </a-form>
    </a-card>
  </div>
</template>
<script>
import { ref } from 'vue'
import { message } from 'ant-design-vue'
import { updatePassword } from '../../services/axios/pulih'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const passwordData = ref({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    })
    const router = useRouter()
    const loading = ref(false)

    const handleChangePassword = async () => {
      if (
        !passwordData.value.currentPassword ||
        !passwordData.value.newPassword ||
        !passwordData.value.confirmPassword
      ) {
        message.error('Semua kolom harus diisi.')
        return
      }

      if (passwordData.value.newPassword !== passwordData.value.confirmPassword) {
        message.error('Password baru dan konfirmasi password tidak cocok.')
        return
      }

      loading.value = true
      console.log(passwordData.value)
      const res = await updatePassword(
        passwordData.value.currentPassword,
        passwordData.value.newPassword,
      )
      if (res.data) {
        router.push('/profil')
      }
    }

    return {
      passwordData,
      loading,
      handleChangePassword,
    }
  },
}
</script>
<style lang="scss">
.new-password-card {
  margin: 0 auto;
  padding: 20px;
}

.form-item {
  display: flex;
  margin-bottom: 20px;
}

.label-col {
  text-align: right;
  padding-right: 15px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.custom-button-yes {
  font-size: 14px;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

@media (max-width: 576px) {
  .label-col {
    text-align: left;
    padding-bottom: 10px;
  }

  .form-item {
    flex-direction: column;
  }
}
</style>
